.contextMenu {
	position: absolute;
	width: 120px;
	z-index: 99999;
	border: solid 1px #CCC;
	background: #EEE;
	padding: 0px;
	margin: 0px;
	display: none;
}

.contextMenu LI {
	list-style: none;
	padding: 0px;
	margin: 0px;
}

.contextMenu A {
	color: #333;
	text-decoration: none;
	display: block;
	line-height: 20px;
	height: 20px;
	background-position: 6px center;
	background-repeat: no-repeat;
	outline: none;
	padding: 1px 5px;
}

.contextMenu LI.hover A {
	color: #FFF;
	background-color: #3399FF;
}

.contextMenu LI.disabled A {
	color: #AAA;
	cursor: default;
}

.contextMenu LI.hover.disabled A {
	background-color: transparent;
}

.contextMenu LI.separator {
	border-top: solid 1px #CCC;
}