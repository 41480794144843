/* websites (pictures and movies) */
a[href *="youtube.com/watch?"], a[href *="sevenload.com/videos/"], a[href *="metacafe.com/watch/"] { 
	padding: 5px 0px 5px 20px;
	background: transparent url(/public/images/icons/icon_film.gif) no-repeat center left;
}

a[href *="flickr.com/photos/"], a[href *="zooomr.com"], a[href *="imageshack.us"], a[href *="bubbleshare.com"], a[href *="sevenload.com/bilder/"] { 
	padding: 5px 0px 5px 20px;
	background: transparent url(/public/images/icons/icon_pic.gif) no-repeat center left;
}

/* extensions */
/*a[href$='.txt'], a[href*='.'] {*/
a[href$='.txt'] {
	padding: 5px 0px 5px 20px;
	background: transparent url(/public/images/icons/icon_txt.gif) no-repeat center left;
}
a[href$='.shp'] {
	padding: 5px 0px 5px 20px;
	background: transparent url(/public/images/icons/shp_icon_16x16.gif) no-repeat center left;
}
a[href$='.ipynb'] {
	padding: 5px 0px 5px 20px;
	background: transparent url(/public/images/icons/jupyter_16x16.png) no-repeat center left;
}

a[href$='.doc'], a[href$='.docx'], a[href$='.rtf'] {
	padding: 5px 0px 5px 20px;
	background: transparent url(/public/images/icons/icon_doc.gif) no-repeat center left;
}

a[href$='xls'], a[href$='.xlsx'] {
	padding: 5px 0px 5px 20px; background-color:red;
	background: transparent url(/public/images/icons/icon_xls.gif) no-repeat center left;
}

a[href$='.rss'], a[href$='.atom'] { 
	padding: 5px 0px 5px 20px;
	background: transparent url(/public/images/icons/icon_feed.gif) no-repeat center left;
}

a[href$='.opml'] { 
	padding: 5px 0px 5px 20px;
	background: transparent url(/public/images/icons/icon_opml.gif) no-repeat center left;
}

a[href$='.phps'] { 
	padding: 5px 0px 5px 20px;
	background: transparent url(/public/images/icons/icon_phps.gif) no-repeat center left;
}

a[href$='.phps'] { 
	padding: 5px 0px 5px 20px;
	background: transparent url(/public/images/icons/icon_phps.gif) no-repeat center left;
}

a[href$='.torrent'] { 
	padding: 5px 0px 5px 20px;
	background: transparent url(/public/images/icons/icon_torrent.gif) no-repeat center left;
}

a[href$='.vcard'] { 
	padding: 5px 0px 5px 20px;
	background: transparent url(/public/images/icons/icon_vcard.gif) no-repeat center left;
}

a[href$='.exe'] { 
	padding: 5px 0px 5px 20px;
	background: transparent url(/public/images/icons/icon_exe.gif) no-repeat center left;
}

a[href$='.dmg'], a[href$='.app'] { 
	padding: 5px 0px 5px 20px;
	background: transparent url(/public/images/icons/icon_dmg.gif) no-repeat center left;
}

a[href$='.pps'] { 
	padding: 5px 0px 5px 20px;
	background: transparent url(/public/images/icons/icon_pps.gif) no-repeat center left;
}

a[href$='.ppt'], a[href$='.pptx'] { 
	padding: 5px 0px 5px 20px;
	background: transparent url(/public/images/icons/icon_ppt.gif) no-repeat center left;
}

a[href$='.pdf'] { 
	padding: 5px 0px 5px 20px;
	background: transparent url(/public/images/icons/icon_pdf.gif) no-repeat center left;
}

a[href$='.xpi'] { 
	padding: 5px 0px 5px 20px;
	background: transparent url(/public/images/icons/icon_plugin.gif) no-repeat center left;
}

a[href$='.fla'], a[href$='.swf'] { 
	padding: 5px 0px 5px 20px;
	background: transparent url(/public/images/icons/icon_flash.gif) no-repeat center left;
}

a[href$='.zip'], a[href$='.rar'], a[href$='.gzip'], a[href$='.bzip'], a[href$='.ace'] { 
	padding: 5px 0px 5px 20px;
	background: transparent url(/public/images/icons/icon_archive.gif) no-repeat center left;
}

a[href$='.ical'] { 
	padding: 5px 0px 5px 20px;
	background: transparent url(/public/images/icons/icon_ical.gif) no-repeat center left;
}

a[href$='.css'] { 
	padding: 5px 0px 5px 20px;
	background: transparent url(/public/images/icons/icon_css.gif) no-repeat center left;
}

a[href$='.ttf'] { 
	padding: 5px 0px 5px 20px;
	background: transparent url(/public/images/icons/icon_ttf.gif) no-repeat center left;
}

a[href$='.jpg'], a[href$='.JPG'], a[href$='.gif'], a[href$='.png'], a[href$='.bmp'], a[href$='.jpeg'], a[href$='.svg'], a[href$='.eps'] { 
	padding: 5px 0px 5px 20px;
	background: transparent url(/public/images/icons/icon_pic.gif) no-repeat center left;
}

a[href$='.mov'], a[href$='.wmv'], a[href$='.mp4'], a[href$='.avi'], a[href$='.mpg'] { 
	padding: 5px 0px 5px 20px;
	background: transparent url(/public/images/icons/icon_film.gif) no-repeat center left;
}

a[href$='.mp3'], a[href$='.wav'], a[href$='.ogg'], a[href$='.wma'], a[href$='.m4a'] { 
	padding: 5px 0px 5px 20px;
	background: transparent url(/public/images/icons/icon_music.gif) no-repeat center left;
}

a[href$='.xml'], a[href$='.xsd'] { 
	padding: 5px 0px 5px 20px;
	background: transparent url(/public/images/icons/icon_xml.gif) no-repeat center left;
}

/* messenger */
a[href ^="aim:"] {
	padding: 5px 0px 5px 20px;
    background: transparent url(/public/images/icons/icon_aim.gif) no-repeat center left;
}

a[href ^="msnim:"] {
	padding: 5px 0px 5px 20px;
    background: transparent url(/public/images/icons/icon_msn.gif) no-repeat center left;
}

a[href ^="xmpp:"] {
	padding: 5px 0px 5px 20px;
    background: transparent url(/public/images/icons/icon_jabber.gif) no-repeat center left;
}

a[href *="icq.com"] { 
	padding: 5px 0px 5px 20px;
	background: transparent url(/public/images/icons/icon_icq.gif) no-repeat center left;
}

a[href *="edit.yahoo.com/config/send_webmesg?"] { 
	padding: 5px 0px 5px 20px;
	background: transparent url(/public/images/icons/icon_yim.gif) no-repeat center left;
}

a[href ^="callto:"] {
	padding: 5px 0px 5px 20px;
    background: transparent url(/public/images/icons/icon_call.gif) no-repeat center left;
}

a[href ^="skype:"] {
	padding: 5px 0px 5px 20px;
    background: transparent url(/public/images/icons/icon_skype.gif) no-repeat center left;
}

a[href ^="gg:"] {
	padding: 5px 0px 5px 20px;
    background: transparent url(/public/images/icons/icon_gadugadu.gif) no-repeat center left;
}

/* email */
a[href ^="mailto:"] {
	padding: 5px 0px 5px 20px;
    background: transparent url(/public/images/icons/icon_mailto.gif) no-repeat center left;
}

/* use this class to make sure that images won't get iconized */

.imageLink {
	padding-right: 0px !important;
	background: none !important;
}